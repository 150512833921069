/* .form-container{
    height: 80%;
    width: 42%;
    position: relative;
    float: left;
    margin-top: 25px;
} */
.examAddForm input, .examAddForm select{
    border-radius: 3px;
    border-width: 1px;
    width:50%;
    margin-bottom: 15px;
}
.examAddForm label{
    width:50%;
}
.my-subject{
    /* border-radius: 3px;
    border-width: 1px;
    width: 50%;
    margin-bottom: px;
    box-sizing: border-box;
    padding: 2px;
    height: 2vw;
    font-size: 1vw;
    background-color: rgb(231, 253, 253);
    float: right; */
}
/* .examAddForm input:focus{
    border: 1px solid rgb(40, 131, 227);

} */
/* .ExamHead
{
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: 500;
    color:blue;
} */
/* .examAddForm button{
    background-color:blue;
    border: none;
    color: white;
    text-decoration: none;
    cursor: pointer;
    height: 3vw;
    width: 16vw;
    font-size: 1.3vw;
    border-radius: 2.2px;
    margin-top: 45px;
    margin-left: 10vw;
} */
/* .examAddForm button:hover{
    opacity: 0.8;
} */

.error{
    color:red;
    text-align: end;
    margin-bottom: 0%;
    font-size: smaller;
    
}