.table td {
    text-align: center;
    vertical-align: middle;
    
}
.table tr:hover{
    background-color: rgb(191, 214, 243);
}
.table th{
    text-align: center;
    /* font-size: 1.2vw; */
}
.table td,th{
    border: 1px solid #dddddd;
}
.table{
    margin-top: 2vw;
}

label {
    display: inline !important;
}

.Exheader {
    position: sticky;
    top: 0;
    background-color: #007aff !important; 
    z-index: 1;
    color: white;
  }