.form-container{
    height: 80%;
    /* width: 42%;
    position: relative;
    float: left;
    margin-top: 25px; */
}
.subjectAddForm input{

    border-radius: 3px;
    border-width: 1px;
    width:17vw;
    margin-bottom: 15px;
    box-sizing: border-box;
    padding: 2px;
    height: 2vw;
    font-size: 1vw;
    background-color:rgb(231, 253, 253);
    margin-left: 5px;
    float: right;

}
.subjectAddForm label{
    font-size: 1.3vw;
    margin-bottom: 15px;
    padding: 2px;
    height: 2vw;
    color:rgb(40, 131, 227);
}
.subjectAddForm input:focus{
    border: 1px solid rgb(40, 131, 227);

}
.SubjectHead
{
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: 500;
    color:blue;
    
}
.subjectAddForm button{
    background-color:blue;
    border: none;
    color: white;
    text-decoration: none;
    cursor: pointer;
    height: 3vw;
    width: 16vw;
    font-size: 1.3vw;
    border-radius: 2.2px;
    margin-top: 45px;
    margin-left: 10vw;
}
.subjectAddForm button:hover{
    opacity: 0.8;
}
